import React from 'react';
// import { Link as RouterLink } from 'gatsby';

// import { Link as RouterLink } from 'react-router-dom';
// import gql from 'graphql-tag';
// import { graphql } from 'react-apollo';
import { graphql } from 'gatsby';

import Markdown from 'react-markdown';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

import Gallery from '../components/Gallery';

import RelatedPersonCard from '../components/RelatedPersonCard';
import RelatedLoreCard from '../components/RelatedLoreCard';
import RelatedInvestigationCard from '../components/RelatedInvestigationCard';
import RelatedTourCompanyCard from '../components/RelatedTourCompanyCard';
import RelatedBookCard from '../components/RelatedBookCard';


import GoogleMapsContainer from '../components/GoogleMapsContainer';
// import PlaceMap from '../components/PlaceMap';
import Layout from '../components/Layout';
import SEO, {SEODescription, SEOImage} from '../components/SEO';



const useStyles = makeStyles((theme) => ({
  articleContainer: {
    padding: theme.spacing(2),
  },
  section: {
    paddingTop: theme.spacing(2),
  },
  descriptionMapContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  descriptionContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  mapContainer: {
    position: "relative",
    minHeight: "250px",
    maxHeight: "500px",
  },
}));

const Place = ({ data }) => {
  const classes = useStyles();
  const place = data.gcms.place;

  return (
    <Layout>

      <SEO 
        title={place.name}
        description={SEODescription(place.description.text)}
        image={SEOImage(place.gallery[0].handle)}
        article={true}
      />

      <Paper className={classes.articleContainer}>
        <article>
          <Typography variant="h3" gutterBottom={true}>
            {place.name}
          </Typography>
          <Gallery gallery={place.gallery} />

          <Grid container className={classes.descriptionMapContainer} >
            <Grid item xs={12} sm={6} className={classes.descriptionContainer} >
              <Markdown source={place.description.markdown} escapeHtml={false} />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.mapContainer} >
              <GoogleMapsContainer place={place} />
              {/* <PlaceMap place={place} /> */}
            </Grid>
          </Grid>

          <Divider variant="middle" />

          {place.people.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                People who haunt this place
              </Typography>
              <Grid container spacing={1}>
                {place.people.map(person => (
                  <RelatedPersonCard
                    key={person.id}
                    person={person}
                  />
                ))}
              </Grid>
            </section>
          }

          {place.lores.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                Stories about this place
              </Typography>
              <Grid container spacing={1}>
                {place.lores.map(lore => (
                  <RelatedLoreCard
                    key={lore.id}
                    lore={lore}
                  />
                ))}
              </Grid>
            </section>
          }

          {place.investigations.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                Investigations done here
              </Typography>
              <Grid container spacing={1}>
                {place.investigations.map(investigation => (
                  <RelatedInvestigationCard
                    key={investigation.id}
                    investigation={investigation}
                  />
                ))}
              </Grid>
            </section>
          }

          {place.tourCompanies.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                Tours that come here
              </Typography>
              <Grid container spacing={1}>
                {place.tourCompanies.map(tourCompany => (
                  <RelatedTourCompanyCard
                    key={tourCompany.id}
                    tourCompany={tourCompany}
                  />
                ))}
              </Grid>
            </section>
          }

          {place.books.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                Books with info on this place
              </Typography>
              <Grid container spacing={1}>
                {place.books.map(book => (
                  <RelatedBookCard
                    key={book.id}
                    book={book}
                  />
                ))}
              </Grid>
            </section>
          }

        </article>
      </Paper>
    </Layout>
  );
};

export const query = graphql`
  query singlePlace($slug: String!) {
    gcms {
      place(where: {slug: $slug}) {
        id
        name
        gallery {
          handle
          title
        }
        description {
          markdown
          text
        }
        location {
          latitude
          longitude
        }
        city {
          name
          state {
            name
            country {
              name
            }
          }
        }
        placeType {
          name
        }
        people {
          id
          name
          slug
          gallery(first: 1) {
            handle
            title
          }
        }
        lores {
          id
          title
          slug
          gallery(first: 1) {
            handle
            title
          }
        }
        investigations {
          id
          title
          slug
          image {
            handle
            title
          }
        }
        tourCompanies {
          id
          name
          slug
          image {
            handle
            title
          }
        }
        books {
          id
          title
          slug
          image {
            handle
            title
          }
        }
      }
    
    }
  }
`;

export default Place;

// const Place = ({ data: { loading, error, place } }) => {
//   const classes = useStyles();

//   if (error) return <h1>Error fetching the place!</h1>;
//   if (!loading) {
//     return (
//       <Paper className={classes.articleContainer}>
//         <article>
//           <Typography variant="h3" gutterBottom={true}>
//             {place.name}
//           </Typography>
//           <Gallery gallery={place.gallery} />

//           <Grid container className={classes.descriptionMapContainer} >
//             <Grid item xs={12} sm={6} className={classes.descriptionContainer} >
//               <Markdown source={place.description.markdown} escapeHtml={false} />
//             </Grid>
//             <Grid item xs={12} sm={6} className={classes.mapContainer} >
//               <GoogleMapsContainer place={place} />
//               {/* <PlaceMap place={place} /> */}
//             </Grid>
//           </Grid>

//           <Divider variant="middle" />

//           {place.people.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 People who haunt this place
//               </Typography>
//               <Grid container spacing={1}>
//                 {place.people.map(person => (
//                   <RelatedCard
//                     title={person.name}
//                     link={`/person/${person.slug}`}
//                     imageHandle={person.gallery[0].handle}
//                     imageTitle={person.gallery[0].title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//           {place.lores.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 Stories about this place
//               </Typography>
//               <Grid container spacing={1}>
//                 {place.lores.map(lore => (
//                   <RelatedCard
//                     title={lore.title}
//                     link={`/lore/${lore.slug}`}
//                     imageHandle={lore.gallery[0].handle}
//                     imageTitle={lore.gallery[0].title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//           {place.investigations.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 Investigations done here
//               </Typography>
//               <Grid container spacing={1}>
//                 {place.investigations.map(investigation => (
//                   <RelatedCard
//                     title={investigation.title}
//                     link={`/investigation/${investigation.slug}`}
//                     imageHandle={investigation.image.handle}
//                     imageTitle={investigation.image.title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//           {place.tourCompanies.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 Tours that come here
//               </Typography>
//               <Grid container spacing={1}>
//                 {place.tourCompanies.map(tourCompany => (
//                   <RelatedCard
//                     title={tourCompany.name}
//                     link={`/tourcompany/${tourCompany.slug}`}
//                     imageHandle={tourCompany.image.handle}
//                     imageTitle={tourCompany.image.title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//           {place.books.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 Books with info on this place
//               </Typography>
//               <Grid container spacing={1}>
//                 {place.books.map(book => (
//                   <RelatedCard
//                     title={book.title}
//                     link={`/book/${book.slug}`}
//                     imageHandle={book.image.handle}
//                     imageTitle={book.image.title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//         </article>
//       </Paper>
//     );
//   }
//   return <h2>Loading place...</h2>;
// };

// export const singlePlace = gql`
//   query singlePlace($slug: String!) {
//     place(where: {slug: $slug}) {
//       id
//       name
//       gallery {
//         handle
//         title
//       }
//       description {
//         markdown
//       }
//       location {
//         latitude
//         longitude
//       }
//       city
//       state
//       placeType {
//         name
//       }
//       people {
//         name
//         slug
//         gallery(first: 1) {
//           handle
//           title
//         }
//       }
//       lores {
//         title
//         slug
//         gallery(first: 1) {
//           handle
//           title
//         }
//       }
//       investigations {
//         title
//         slug
//         image {
//           handle
//           title
//         }
//       }
//       tourCompanies {
//         name
//         slug
//         image {
//           handle
//           title
//         }
//       }
//       books {
//         title
//         slug
//         image {
//           handle
//           title
//         }
//       }
//     }
//   }
// `;

// export default graphql(singlePlace, {
//   options: ({ match }) => ({
//     variables: {
//       slug: match.params.slug,
//     },
//   }),
// })(Place);