import React from 'react';
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';

import {styles} from '../google-maps-styles';

export class GoogleMapsContainer extends React.Component {

  // Left this junk around because we might want to eventually show other nearby places.
  
  // state = {
  //   showingInfoWindow: false,
  //   activeMarker: {},
  //   selectedPlace: {},
  // };

  // onMarkerClick = (props, marker, e) => {
  //   this.setState({
  //     selectedPlace: props,
  //     activeMarker: marker,
  //     showingInfoWindow: true
  //   });
  // }

  // onMapClick = (props) => {
  //   if (this.state.showingInfoWindow) {
  //     this.setState({
  //       showingInfoWindow: false,
  //       activeMarker: null
  //     });
  //   }
  // }


  render() {
    const place = this.props.place;
    
    return (
      <Map
        google={this.props.google}
        mapTypeControl={false}
        onClick={this.onMapClick}
        zoom={11}
        initialCenter={{ lat: place.location.latitude, lng: place.location.longitude }}
        styles={styles}
      >
        <Marker
          // onClick={this.onMarkerClick}
          // title = { 'Changing Colors Garage' }
          position={{ lat: place.location.latitude, lng: place.location.longitude }}
          name={place.name}
          icon={{url: 'https://maps.google.com/mapfiles/ms/icons/purple-dot.png'}}
        />
        
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  // apiKey: (process.env.REACT_APP_GOOGLE_API_KEY)
  // apiKey: (process.env.GATSBY_GOOGLE_API_KEY)
  apiKey: ("AIzaSyAWxrT3f-MaZMCDb1D_2EcuvM7OYcteqDQ")
})(GoogleMapsContainer)